@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


*{
    margin: 0;
    padding: 0;
}


#dark{
    background-color: #000000;

}